<template>
	<div class="pd-b-20 rel">
		<div class="fixed pd-l-10 hide" style="margin-left:-20px;margin-top:20px;background-color: rgba(0,0,0,.05);border:1px solid #ccc;z-index:2;border-left:0 !important;border-radius: 0 5px 5px 0;" v-if="showAutoSave">
			<div class="cl">
				<div class="z pd-t-b-5">
					<div class="f-12 dark-4">快捷键：Ctrl+S</div>
					<div class="f12" :class="tmpIsSave ? 'green-1' : 'orange-1'">
						<i class="el-icon-success" v-if="tmpIsSave"></i>
						<i class="el-icon-warning" v-if="!tmpIsSave"></i>
						{{getTmp() ? '草稿已保存' : '草稿未保存'}}
					</div>
					<el-button size="mini" class="mg-t-5 block" type="success" @click="saveTmp">保存草稿</el-button>
					<el-button size="mini" class="mg-t-5 block" style="margin-left:0 !important;" @click="delTmp">清空草稿</el-button>
				</div>
				<div class="y mg-l-5 hand hover-bg-blue-1" style="height: 100%;width: 15px;line-height:111px;padding-right:5px;" @click="toggleShowSave">
					<i class="el-icon-d-arrow-left f-12"></i>
				</div>
			</div>
		</div>
		<div class="fixed hand hover-bg-blue-1" style="margin-left:-20px;margin-top:20px;background-color: rgba(0,0,0,.05);border:1px solid #ccc;z-index:2;border-left:0 !important;border-radius: 0 5px 5px 0;width:15px;height: 111px;line-height: 111px;" @click="toggleShowSave" v-else>
			<i class="el-icon-d-arrow-right f-12"></i>
		</div>
		<el-form :model="formData" :rules="rules" label-width="150px" ref="form">
<!--			{{formData}}-->
<!--			<div>{{times}}</div>-->
<!--			{{formData.dict_program_ids}}-->
			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item label="事项内容" prop="title">
						<el-input v-model="formData.title" maxlength="200" style="width: 500px;" show-word-limit></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="type === 'hysx'">
					<el-form-item label="所属专题会议" prop="special_id">
						<base-tree-select v-model="formData.special_id" url="/Special/listTree" :datas="{state:1,delete_time:0,orderBy:'sort',sortBy:'asc'}" :clearable="true" val-label="title" style="width: 350px;"></base-tree-select>
						<!--						<type-select v-model="formData.special_id" url="/Special/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc'}" :is-top="false" style="width: 350px;"></type-select>-->
					</el-form-item>
				</el-col>
<!--				<el-col :span="24">-->
<!--					<el-form-item label="副标题" prop="sub_title">-->
<!--						<el-input v-model="formData.sub_title" maxlength="50" style="width: 100%;" show-word-limit></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
				<el-col :span="24">
					<el-form-item label="封面图" prop="head_img">
						<head-img-upload v-model="formData.head_img" class="small-img-upload"></head-img-upload>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="事项详述" prop="html_content">
						<el-input v-model="formData.html_content" style="width: 500px;" maxlength="1000" type="textarea" :rows="6" show-word-limit></el-input>
					</el-form-item>
				</el-col>
<!--				<el-col :span="12">-->
<!--					<el-form-item label="事项分类" prop="type">-->
<!--						<type-select v-model="formData.type" :options="[{id:'ldjb',name:'领导交办'},{id:'hysx',name:'会议事项'},{id:'zysx',name:'四个重大'}]" :is-top="false" style="width: 350px;"></type-select>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
				<el-col :span="24">
					<el-form-item label="事项类型" prop="dict_program_ids">
						<type-select v-model="formData.dict_program_ids" url="/SystemDict/listAll" :data="{type:'program',state:1,delete_time:0,orderBy:'sort',sortBy:'asc'}" :pid="formData.type" pid-data-key="akey" :auto-load="false"  :is-top="false" style="width: 500px;"></type-select>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="牵头单位"  prop="qtdw" required :rules="[{required:true,message:'请选择牵头单位'}]">
						<el-table :data="formData.qtdw" :show-header="true" border size="mini" row-key="id">
							<el-table-column label="单位名称">
								<template slot-scope="scope">
									<el-form-item label="" :prop="'qtdw.'+scope.$index+'.dept_id'" :rules="[{required:true,message:'请选择牵头单位'}]" style="padding-bottom: 22px !important;">
										<type-select v-model="scope.row.dept_id" url="/Dept/listAll" :data="{state:1,delete_time:0,orderBy:'sort',sortBy:'asc',is_qtdw:1}"  :is-top="false" filterable style="width: 100%;" val-key="dept_id"></type-select>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="领导">
								<template slot-scope="scope">
<!--									<el-form-item label="" :prop="'qtdw.'+scope.$index+'.lingdao_uid'" :rules="[{required:true,message:'请选择牵头单位的领导'}]" style="padding-bottom: 22px !important;">-->
									<el-form-item label="" :prop="'qtdw.'+scope.$index+'.lingdao_uid'" style="padding-bottom: 22px !important;">
										<type-select clearable v-model="scope.row.lingdao_uid" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc',job:'lingdao',can_clean:'0'}" :placeholder="scope.row.dept_id ? '请选择领导' : '请先选择牵头单位'" :pid="scope.row.dept_id" pid-data-key="dept_id" :auto-load="!!scope.row.dept_id" :is-top="false" filterable :multiple="false" style="width: 100%;"  right-key="job_str" :ref="'qtdw_lingdao_'+scope.$index" auto-first></type-select>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="联络员">
								<template slot-scope="scope">
									<el-form-item label="" :prop="'qtdw.'+scope.$index+'.bsy_uid'" :rules="[{required:true,message:'请选择责任单位的联络员'}]" style="padding-bottom: 22px !important;">
										<type-select clearable v-model="scope.row.bsy_uid" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc',job:'bsy',can_clean:'0'}" :placeholder="scope.row.dept_id ? '请选择联络员' : '请先选择责任单位'" :pid="scope.row.dept_id" pid-data-key="dept_id" :auto-load="!!scope.row.dept_id" :is-top="false" filterable :multiple="false" style="width: 100%;"  right-key="job_str" :ref="'qtdw_bsy_'+scope.$index" auto-first></type-select>
									</el-form-item>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
<!--					<el-form-item label="牵头单位" prop="qtdw">-->
<!--						<type-select v-model="formData.qtdw" url="/Dept/listAll" :data="{state:1,delete_time:0,orderBy:'sort',sortBy:'asc'}" :is-top="false" filterable style="width: 350px;" val-key="dept_id"></type-select>-->
<!--					</el-form-item>-->
				</el-col>
				<el-col :span="24">
					<el-form-item label="责任单位"  prop="zrdw">
						<el-table :data="formData.zrdw" :show-header="true" border size="mini" row-key="id">
							<el-table-column label="单位名称">
								<template slot-scope="scope">
									<el-form-item label="" :prop="'zrdw.'+scope.$index+'.dept_id'" :rules="[{required:true,message:'请选择责任单位'}]" style="padding-bottom: 22px !important;">
										<type-select v-model="scope.row.dept_id" url="/Dept/listAll" :data="{state:1,delete_time:0,orderBy:'sort',sortBy:'asc',is_zrdw:1}"  :is-top="false" filterable style="width: 100%;" val-key="dept_id"></type-select>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="领导">
								<template slot-scope="scope">
									<el-form-item label="" :prop="'zrdw.'+scope.$index+'.lingdao_uid'" :rules="[{required:true,message:'请选择责任单位的领导'}]" style="padding-bottom: 22px !important;">
										<type-select clearable v-model="scope.row.lingdao_uid" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc',job:'lingdao',can_clean:'0'}" :placeholder="scope.row.dept_id ? '请选择领导' : '请先选择责任单位'" :pid="scope.row.dept_id" pid-data-key="dept_id" :auto-load="!!scope.row.dept_id" :is-top="false" filterable :multiple="false" style="width: 100%;"  right-key="job_str" :ref="'lingdao_'+scope.$index" auto-first></type-select>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="联络员">
								<template slot-scope="scope">
									<el-form-item label="" :prop="'zrdw.'+scope.$index+'.bsy_uid'" :rules="[{required:true,message:'请选择责任单位的联络员'}]" style="padding-bottom: 22px !important;">
										<type-select clearable v-model="scope.row.bsy_uid" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc',job:'bsy',can_clean:'0'}" :placeholder="scope.row.dept_id ? '请选择联络员' : '请先选择责任单位'" :pid="scope.row.dept_id" pid-data-key="dept_id" :auto-load="!!scope.row.dept_id" :is-top="false" filterable :multiple="false" style="width: 100%;"  right-key="job_str" :ref="'bsy_'+scope.$index" auto-first></type-select>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="" width="100px">
								<template slot="header" slot-scope="scope">
									<el-button type="warning" size="mini" @click="toAddZrdw">新增</el-button>
								</template>
								<template slot-scope="scope">
									<el-button type="text" @click="toDelZrdw(scope.$index)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="是否周期汇报" prop="is_around">
						<radio-group v-model="formData.is_around" :options="[{label:'是',value:1},{label:'否',value:0}]" @change="onChangeIsAround"></radio-group>
					</el-form-item>
				</el-col>
				<el-col :span="24" v-if="formData.is_around === 1">
					<el-form-item label="汇报周期">
						<el-form-item label="" prop="around_time" :rules="[{required:true,message:'周期必填'},{pattern:$regexp.integer,message:'请输入大于0的整数'}]" class="inline-block">
							每<el-input v-model="formData.around_time" maxlength="2" show-word-limit style="width: 230px;margin-left: 5px;"></el-input>
						</el-form-item>
						<el-form-item label="" prop="around_type" class="inline-block" :rules="[{required:true,message:'周期类型必填'}]">
							<el-select v-model="formData.around_type" placeholder="请选择" style="width: 100px;" @change="onChangeAroundType">
								<el-option label="周" value="week"></el-option>
								<el-option label="月" value="month"></el-option>
								<el-option label="年" value="year"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="" prop="around_do_time" class="inline-block" :rules="[{required:true,message:'汇报日期必填'}]">
							<el-select v-model="formData.around_do_time" placeholder="请选择" style="width: 100px;" v-if="formData.around_type === 'week'">
								<el-option label="周一" value="1"></el-option>
								<el-option label="周二" value="2"></el-option>
								<el-option label="周三" value="3"></el-option>
								<el-option label="周四" value="4"></el-option>
								<el-option label="周五" value="5"></el-option>
								<el-option label="周六" value="6"></el-option>
								<el-option label="周日" value="7"></el-option>
							</el-select>
							<el-select v-model="formData.around_do_time" placeholder="请选择" style="width: 100px;" v-if="formData.around_type === 'month'">
								<el-option :label="item+'日'" :value="item" v-for="(item,index) in 31"></el-option>
							</el-select>
							<push-time v-model="formData.around_do_time" style="width: 120px;" v-if="formData.around_type === 'year'" format="M月d日"></push-time>
						</el-form-item>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="交办时间" prop="start_time" :rules="[{required:true,message:'交办时间不能为空'}]">
						<push-time key="start_time" now v-model="formData.start_time" style="width: 350px;" @change="onChangeStartTime"></push-time>
					</el-form-item>
				</el-col>
<!--				<el-col :span="12" v-else>-->
<!--					<el-form-item label="周期开始时间" prop="start_time" :rules="[{required:true,message:'周期开始时间不能为空'}]">-->
<!--						<push-time v-model="formData.start_time" style="width: 350px;" @change="onChangeStartTime"></push-time>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
				<el-col :span="12">
					<el-form-item label="办理时限" prop="end_time" :rules="formData.is_long === 0 ? [{required:true,message:'办理时限不能为空'}] : null">
						<one-checkbox v-model="formData.is_long" @change="onChangeIsLong" class="mg-r-10">长期</one-checkbox>
						<push-time v-if="formData.is_long === 0" v-model="formData.end_time" style="width: 287px;" :start-time="formData.start_time" key="end_time"></push-time>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="督办员" prop="dby_ids">
						<type-select clearable v-model="formData.dby_ids" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc',dept_id:dept_id,job:'dby',can_clean:'0'}" v-if="dept_id"  :is-top="false" filterable :multiple="false" style="width: 350px;" right-key="job_str" auto-first></type-select>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="附件" prop="files_ids">
						<file-upload v-model="formData.files_ids" :files="formData['files']" img-key="program_file" totype="dingpan" :limit="5" accept=".doc,.docx,.ppt,.pptx,.pdf,.xls,.xlsx,.txt"></file-upload>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="相关图片" prop="images_ids">
						<image-upload class="small-img-upload" v-model="formData.images_ids" :files="formData['images']" img-key="program_image"></image-upload>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="相关视频" prop="videos_ids">
						<file-upload v-model="formData.videos_ids" accept=".mp4" :files="formData['videos']" img-key="program_video"></file-upload>
					</el-form-item>
				</el-col>
<!--				<el-col :span="24">-->
<!--					<el-form-item label="领导" prop="lingdao_ids">-->
<!--						<type-select clearable v-model="formData.lingdao_ids" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc'}"  :is-top="false" filterable :multiple="false" style="width: 350px;"></type-select>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="24">-->
<!--					<el-form-item label="办事员" prop="bsy_ids">-->
<!--						<type-select clearable v-model="formData.bsy_ids" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc'}"  :is-top="false" filterable :multiple="false" style="width: 350px;"></type-select>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
			</el-row>
			<el-form-item class="mg-t-20">
				<el-button @click="toBack">返回</el-button>
				<el-button type="primary" @click="toSave">保存</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
export default {
	name: "edit",
	data() {
		return {
			type:'',
			showAutoSave:false,
			isSaveTmp:false,
			formData:{
				id:'',
				type:'',
				title:'',
				sub_title:'',
				head_img:'',
				special_id:'',
				html_content:'',
				dict_program_ids:'',
				images_ids:[],
				files_ids:[],
				videos_ids:[],
				zrdw:[],
				qtdw:[{ "dept_id": "", "lingdao_uid": "", "bsy_uid": "" }],
				lingdao_ids:[],
				bsy_ids:[],
				dby_ids:[],
				pjy_ids:[],
				start_time:'',
				end_time:'',
				is_long:0,
				is_around:0,
				around_do_time:'',
				around_time:'',
				around_type:''
			},
			dept_id:'',
			times:'',
			rules:{
				title:[{required:true,message:'标题不能为空'}],
				type:[{required:true,message:'事项分类不能为空'}],
				dict_program_ids:[{required:true,message:'事项类型不能为空'}],
				// zrdw:[{required:true,message:'责任单位不能为空'}],
				// qtdw:[{required:true,message:'牵头单位不能为空'}],
				// start_time:[{required:true,message:'交办时间不能为空'}],
				// end_time:[{required:true,message:'办理时限不能为空'}],
				// bsy_ids:[{required:true,message:'办事员不能为空'}],
				dby_ids:[{required:true,message:'督办员不能为空'}],
				// lingdao_ids:[{required:true,message:'领导不能为空'}],
			}
		}
	},
	beforeRouteLeave(to, from, next) {
		console.log(to, from, next)
		// 导航离开该组件的对应路由时调用
		// 可以访问组件实例 `this`
	},
	created() {
		this.formData.type = this.$route.query.type ? this.$route.query.type : '';
		this.type = this.$route.query.type ? this.$route.query.type : '';
		this.formData.id = this.$route.query.id ? this.$route.query.id : '';
		this.dept_id = this.$user.getDepartmentId();
	},
	mounted() {
		let _this = this;

		window.addEventListener('keydown', this.onKeyDown)

		if(this.formData.id){
			this.getDetail();
		}

		this.$nextTick(()=>{
			this.$refs.form.clearValidate();

			if(!this.formData.id) {
				let _form = this.$utils.localGet('program_cache_' + this.type);

				if (_form) {
					this.$confirm('上次的内容还没有保存，是否恢复？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						_this.formData = JSON.parse(_form);
						// _this.delTmp();
					}).catch(() => {
						// _this.delTmp();
					});
				}
			}
		})

		window.onbeforeunload = e => {
			e = e || window.event
			// 兼容IE8和Firefox 4之前的版本
			if (e) {
				e.returnValue = '关闭提示'
			}
			// Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
			return '关闭提示'
		}
	},
	// beforeDestroy() {
	// 	// console.log('===')
	// 	if(this.formData && !this.formData.id && (this.formData.title || this.formData.html_content || this.formData.head_img || this.formData.special_id || this.formData.dict_program_ids || this.formData.dby_ids || this.formData.start_time || this.formData.end_time || (this.formData.files_ids && this.formData.files_ids.length > 0) || (this.formData.images_ids && this.formData.images_ids.length > 0) || (this.formData.videos_ids && this.formData.videos_ids.length > 0))){
	// 		this.$utils.sessionSet('program_cache_'+this.type,JSON.stringify(this.formData));
	// 	}else{
	// 		this.$utils.sessionDel('program_cache_'+this.type);
	// 	}
	// },
	destroyed() {
		window.onbeforeunload = null;
		window.removeEventListener('keydown', this.onKeyDown)
	},
	computed:{
		tmpIsSave(){
			// if(this.isSaveTmp || !this.isSaveTmp){
				if(this.isSaveTmp){

				}
				console.log('=222==')
				return this.getTmp();
			// }
		}
	},
	methods: {
		toggleShowSave(){
			this.showAutoSave = !this.showAutoSave;
		},
		checkTmp(){
			let _form = this.$utils.localGet('program_cache_' + this.type);
			console.log(_form);
		},
		saveTmp(){
			this.$utils.localSet('program_cache_'+this.type,JSON.stringify(this.formData));
			this.isSaveTmp = !this.isSaveTmp;
			this.$utils.success('草稿保存成功');
		},
		delTmp(){
			this.$utils.localDel('program_cache_'+this.type,JSON.stringify(this.formData));
			this.isSaveTmp = !this.isSaveTmp;
			this.$utils.success('草稿删除成功');
		},
		getTmp(){
			return this.$utils.localGet('program_cache_'+this.type,JSON.stringify(this.formData));
		},
		onKeyDown(e){
			if((e.metaKey && e.code === 'KeyS') || (e.ctrlKey && e.code === 'KeyS')){
				console.log('保存');
				this.saveTmp();
				e.preventDefault()
			}
		},
		onChangeIsAround(e){
			this.formData.around_time = '';
			this.formData.around_type = '';
			this.formData.around_do_time = '';

			// if(e === 1){
			// 	this.formData.start_time = '';
			// }
		},
		onChangeAroundType(e){
			this.formData.around_do_time = '';
		},
		onChangeIsLong(e){
			if(e === 1){
				this.formData.end_time = '';
			}
		},
		toAddZrdw(){
			let _data = {dept_id:'',lingdao_uid:'',bsy_uid:''};
			this.formData.zrdw.push(_data);
		},
		toDelZrdw(i){
			this.formData.zrdw.splice(i,1)
		},
		onChangeStartTime(e){
			console.log(e);
			if(this.formData.end_time){
				if(this.$moment(e).unix() > this.$moment(this.formData.end_time).unix()){
					this.formData.end_time = '';
				}
			}
		},
		onChooseImage(e){
			this.formData.files_ids = e;
		},
		changeRangeTime(e) {
			let _times = e.split(',');

			this.$set(this.formData, 'start_time', _times[0]);
			this.$set(this.formData, 'end_time', _times[1]);
		},
		getDetail(){
			let _this = this;
			this.$http.request({
				url:'/Program/detail',
				datas:{id:this.formData.id},
				success(res){
					console.log(res);
					// _this.formData = _this.$utils.deepCopy(res);
					_this.formData.title = res.title;
					_this.formData.html_content = res.html_content;
					_this.formData.start_time = res.start_time;
					_this.formData.end_time = res.end_time;
					_this.formData.zrdw = res.zrdw;
					_this.formData.qtdw = res.qtdw ? res.qtdw : [{ "dept_id": "", "lingdao_uid": "", "bsy_uid": "" }];
					_this.formData.dict_program_ids = res.dict_program_ids;
					_this.formData.dby_ids = res.dby_ids;
					_this.formData.images_ids = res.images_ids;
					_this.formData.files_ids = res.files_ids;
					_this.formData.videos_ids = res.videos_ids;
					_this.formData.id = res.id;
					_this.formData.images = res.images;
					_this.formData.files = res.files;
					_this.formData.videos = res.videos;
					_this.formData.is_long = res.is_long;
					_this.formData.lingdao_ids = res.lingdao_ids;
					_this.formData.bsy_ids = res.bsy_ids;
					_this.formData.type = res.type;
					_this.formData.is_around = res.is_around;
					_this.formData.around_type = res.around_type;
					_this.formData.around_time = res.around_time;
					_this.formData.around_do_time = res.around_do_time;
					// _this.formData.zrdw = [];
					// for (let i=0;i<res.zrdw.length;i++){
					// 	if(i === 0){
					// 		// continue
					// 	}
					// 	_this.formData.zrdw.push({dept_id:res.zrdw[i].dept_id,lingdao_uid:res.zrdw[i].lingdao_uid,bsy_uid:res.zrdw[i].bsy_uid})
					// }
					_this.formData.special_id = res.special_id === '0' ? '' : res.special_id;
					_this.times = res.start_time && res.end_time ? res.start_time+','+res.end_time : '';
				}
			})
		},

		toBack(){
			this.$user.removeTab();
			this.$router.back();
		},

		toSave(){
			let _this = this;

			// if(!this.formData.type){
			// 	this.$utiles.error('缺少事项类型')
			// 	return;
			// }

			let _datas = this.$utils.deepCopy(this.formData);

			let _url = '';

			if(_datas.id){
				_url = '/Program/edit';
			}else{
				_url = '/Program/add';
			}

			this.$refs['form'].validate((valid) => {
				if (valid) {
					let _zrdw = _datas.zrdw;
					let _lingdao = [];
					let _bsy = [];
					for (let i=0;i < _zrdw.length;i++){
						if(_lingdao.indexOf(_zrdw[i].lingdao_uid) < 0){
							_lingdao.push(_zrdw[i].lingdao_uid)
						}

						if(_bsy.indexOf(_zrdw[i].bsy_uid) < 0){
							_bsy.push(_zrdw[i].bsy_uid)
						}
					}

					if(_lingdao.length < _zrdw.length){
						_this.$message.error('责任单位领导不能重复')
						return;
					}

					if(_bsy.length < _zrdw.length){
						_this.$message.error('责任单位办事员不能重复')
						return;
					}

					_this.$http.request({
						url:_url,
						datas:_datas,
						success(res){
							_this.$message.success('保存成功');
							_this.formData = null;
							_this.$utils.sessionDel('program_cache_'+_this.type);
							_this.delTmp();
							_this.$user.removeTab();

							_this.$router.back();
						}
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
	}
}
</script>
